<template>
  <v-row class="bbr-program--details-form">
    <v-col cols="12">
      <v-text-field
        v-model="form.name"
        label="Title"
        :error-messages="form.$getError('name')"
        outlined
        flat
      />

      <v-textarea
        v-model="form.notes"
        label="Notes"
        :error-messages="form.$getError('notes')"
        hide-details
        outlined
        flat
        rows="3"
      />
    </v-col>
  </v-row>
</template>

<script>
import Form from '@/utils/form'

export default {
  name: 'FoodSwapForm',

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  components: {},

  data() {
    return {
      form: this.formData,
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },

  computed: {},

  methods: {},
}
</script>
