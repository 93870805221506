<template>
  <v-card class="mx-auto" outlined>
    <v-card-text class="px-0">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th colspan="2" class="text-center">Macros per Serving</th>
              <th class="text-left">%</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Calories</td>
              <td>{{ calories }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Protein</td>
              <td>{{ protein }}</td>
              <td>{{ proteinValue.toFixed(1) }}</td>
            </tr>
            <tr>
              <td>Fats</td>
              <td>{{ fats }}</td>
              <td>{{ fatsValue.toFixed(1) }}</td>
            </tr>
            <tr>
              <td>Carbs</td>
              <td>{{ carbs }}</td>
              <td>{{ carbsValue.toFixed(1) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    calories() {
      return this.macros('calories')
    },

    protein() {
      return this.macros('protein')
    },

    proteinValue() {
      return this.macroValues('protein', 4)
    },

    fats() {
      return this.macros('fats')
    },

    fatsValue() {
      return this.macroValues('fats', 9)
    },

    carbs() {
      return this.macros('carbs')
    },

    carbsValue() {
      return this.macroValues('carbs', 4)
    },

    fibre() {
      return this.macros('fibre')
    },
  },

  methods: {
    macros(content, isRound = true) {
      const value =
        (this.formData.food[content] / this.formData.food.serving_quantity) *
        this.formData.serving_quantity
      return value > 0.1 ? value.toFixed(2) : 0.0
    },
    macroValues(content, multiplier) {
      const calories = this.protein * 4 + this.fats * 9 + this.carbs * 4
      let value = ((this[content] * multiplier) / calories) * 100

      return value > 0 || !isNaN(value) ? value : 0
    },
  },
}
</script>

<style></style>
