<template>
  <div class="new-program">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id">
          {{ selectedData.name }}
        </span>
        <span v-else> Add New Food Swap </span>
      </template>
    </main-app-bar>

    <div class="data-entry mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8" v-if="form">
      <v-row>
        <v-col sm="8">
          <div class="d-flex justify-space-between align-center">
            <h2 class="mr-auto">
              <span> Food Swap Details </span>
            </h2>

            <v-btn
              class="bg-primary-gradient primary next-button px-12"
              :loading="form.$busy"
              @click="saveAndLoadSwapItems"
              text
            >
              Save
              <v-icon>{{ icons.save }}</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="8">
          <form autocomplete="off">
            <food-swap-form :form-data="form" />
          </form>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col sm="8" v-if="$attrs.id">
          <swap-item-form-list
            :swapId="parseInt($attrs.id)"
            :data="selectedData.items"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import FoodSwapForm from './components/FoodSwapForm'
import SwapItemFormList from './components/SwapItemFormList'
import MainAppBar from '@/layouts/shared/MainAppBar'
import Event from '@/services/eventBus'
import Form from '@/utils/form'

import { mapActions, mapState } from 'vuex'
import { mdiCheck } from '@mdi/js'
import { pick } from 'lodash'

export default {
  name: 'FoodSwapDetails',

  components: {
    MainAppBar,
    FoodSwapForm,
    SwapItemFormList,
  },

  data() {
    return {
      icons: {
        save: mdiCheck,
      },
      form: new Form({
        id: this.$attrs.id,
        name: null,
        notes: null,
      }),
    }
  },

  computed: {
    ...mapState({
      selectedData: (state) => state.foodSwap.selectedData,
    }),
  },

  created() {
    Event.$on('update-selected-food-swap', () => {
      this.fetchData(this.$attrs.id)
    })
  },

  methods: {
    ...mapActions({
      getSingleData: 'foodSwap/getSingleData',
      saveData: 'foodSwap/saveData',
    }),

    async fetchData(id) {
      let { data } = await this.getSingleData(id)
      this.fillForm(data)
    },

    saveForLater() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.saveData(this.form.$data())
        .then((data) => {
          Event.$emit('update-draft-food-swap-list')

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({ name: 'active.food-swap' })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
        })
    },

    saveAndLoadSwapItems() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      payload.completed = true

      this.saveData(payload)
        .then((data) => {
          Event.$emit('update-active-food-swap-list')

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({
              name: 'form.food-swap',
              params: { id: data.id },
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    fillForm(data) {
      data = pick(data, ['id', 'name', 'notes'])

      this.form = new Form(data)
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },
}
</script>
