<template>
  <div>
    <v-dialog v-model="dialog" width="550" eager>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Add Swap Item
        </v-btn>
      </template>

      <v-form autocomplete="off" @submit.prevent="save">
        <v-card class="mx-auto" outlined>
          <v-card-title class="headline">
            {{ isNew ? `Add` : `Edit` }} Food Swap
          </v-card-title>

          <v-divider />

          <v-card-text>
            <swap-item-form :formData="form" :readonlySearch="!isNew" />
          </v-card-text>

          <v-divider />

          <v-card-actions class="pa-5">
            <v-spacer />
            <v-btn class="px-12" text @click="clear">Close</v-btn>
            <v-btn class="px-12 primary" text type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-card
      class="mx-auto mt-4"
      outlined
      v-for="(row, index) in data"
      :key="index"
    >
      <v-card-text>
        <v-alert v-if="row.error" dense outlined border="left" type="error">
          {{ row.error }}
        </v-alert>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="60%">
                {{ row.food.title }}
              </td>

              <td>{{ row.serving_quantity }}</td>
              <td>{{ row.serving_unit }}</td>
              <td width="8%">
                <v-row no-gutters>
                  <v-col cols="6" class="pr-8">
                    <v-icon @click="editItem(row)">
                      {{ icons.pencil }}
                    </v-icon>
                  </v-col>
                  <v-col>
                    <v-icon @click="deleteItem(row)">
                      {{ icons.delete }}
                    </v-icon>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <FoodSwapMacrosTable :formData="row" />
      </v-card-text>
    </v-card>
    <confirm-dialog ref="confirmDelete"></confirm-dialog>
  </div>
</template>

<script>
import SwapItemForm from './SwapItemForm'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import FoodSwapMacrosTable from '@/components/FoodSwapMacrosTable'
import { mapActions } from 'vuex'
import { mdiDotsVertical, mdiPencil, mdiDelete } from '@mdi/js'
import Event from '@/services/eventBus'
import Form from '@/utils/form'

const emptyFields = {
  food_id: null,
  serving_quantity: null,
  serving_unit: null,
  food: {},
  food_id_for_override: 0,
}

export default {
  name: 'SwapItemFormList',

  components: { SwapItemForm, FoodSwapMacrosTable, ConfirmDialog },

  directives: {},

  props: {
    swapId: {
      type: Number,
    },
    data: {
      type: Array,
    },
  },

  data() {
    return {
      icons: {
        action: mdiDotsVertical,
        pencil: mdiPencil,
        delete: mdiDelete,
      },
      loading: false,
      list: this.data ? this.data : [],

      dialog: false,
      isNew: true,
      form: new Form(emptyFields),
    }
  },

  computed: {},

  methods: {
    ...mapActions({
      saveData: 'foodSwap/createSwapItem',
      updateData: 'foodSwap/updateSwapItem',
      deleteData: 'foodSwap/deleteSwapItem',
    }),

    showDetails(row) {
      this.$emit('goTo', row.id)
    },

    clear() {
      this.form.$clearErrors()
      this.isNew = true
      this.dialog = false
      this.form.food = null
      this.form = new Form(emptyFields)
    },

    editItem(row) {
      this.isNew = false
      this.dialog = true
      row.food_id = row.food.id
      this.form = new Form(row)
    },

    save() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      payload.swap_id = this.swapId
      delete payload.food

      const override = payload.food_id_for_override === payload.food_id
      delete payload.override

      if (this.isNew) {
        this.saveData({ params: payload, config: { params: { override } } })
          .then((data) => {
            this.form.$busy = false
            this.clear()

            Event.$emit('update-selected-food-swap')
          })
          .catch((err) => {
            this.form.$timeout(() => {
              if (err.response && err.response.status === 422) {
                this.form.$setErrors(err.response.data.errors)
              }

              this.form.$busy = false
            })
          })
      } else {
        this.updateData(payload)
          .then((data) => {
            this.form.$busy = false
            this.clear()

            Event.$emit('update-selected-food-swap')
          })
          .catch((err) => {
            this.form.$timeout(() => {
              if (err.response && err.response.status === 422) {
                this.form.$setErrors(err.response.data.errors)
              }

              this.form.$busy = false
            })
          })
      }
    },

    async deleteItem(row) {
      const confirm = await this.$refs.confirmDelete.open(
        'Delete Food Swap Item',
        'Are you sure you want to delete?'
      )

      if (confirm) {
        row.swap_id = this.swapId
        try {
          await this.deleteData(row)
        } catch (e) {
          if (e.response.status === 405) {
            this.$set(
              row,
              'error',
              "Can't delete this item. A user may have already swapped for this food item."
            )
          }
        }
      }
    },
  },
}
</script>
