<template>
  <div>
    <v-row class="bbr-program--details-form">
      <v-col cols="12">
        <search-food-input
          v-model="form.food"
          :label="`Search Food Entry`"
          :error-messages="form.$getError('food_id')"
          :hide-details="!form.$getError('food_id')"
          @change="changeFood"
          return-object
          clearable
          outlined
          is-food
          flat
          :disabled="readonlySearch"
        />
        <v-checkbox
          :label="overrideLabel"
          v-model="form.food_id_for_override"
          :true-value="form.food_id"
          :false-value="0"
          v-show="showOverride"
          dense
          hide-details
          class="mt-0"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.serving_quantity"
          label="Serving"
          :error-messages="form.$getError('serving_quantity')"
          hide-details
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.serving_unit"
          label="Unit"
          :error-messages="form.$getError('serving_unit')"
          hide-details
          outlined
          flat
        />
      </v-col>
    </v-row>

    <food-swap-macros-table
      v-if="this.form.food_id"
      :form-data="form"
      class="mt-3"
    />
  </div>
</template>

<script>
import SearchFoodInput from '@/components/elements/foods/SearchFoodInput2'
import FoodSwapMacrosTable from '@/components/FoodSwapMacrosTable'
import Form from '@/utils/form'

import { isNil, isNumber } from 'lodash'

export default {
  name: 'SwapItemForm',

  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Form,
      required: true,
    },
    readonlySearch: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SearchFoodInput,
    FoodSwapMacrosTable,
  },

  data() {
    return {
      form: this.formData,
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },

  methods: {
    changeFood(value) {
      this.form.$clearErrors()
      if (isNil(value) || isNumber(value)) {
        this.form.food_id = null
        this.form.serving_unit = null
        this.form.serving_quantity = null
        this.form.food_id_for_override = 0

        return
      }

      this.form.food = value
      this.form.food_id = value.id
      this.form.serving_unit = value.serving_unit
    },
  },

  computed: {
    showOverride() {
      return (
        this.form.food_id &&
        ((this.form.$errors.food_id &&
          this.form.$errors.food_id[0].indexOf('exist') > -1) ||
          this.form.food_id_for_override === this.form.food_id)
      )
    },
    overrideLabel() {
      return this.form.food
        ? `Add ` +
            this.form?.food.title +
            ` even if it exists in another cluster.`
        : ''
    },
  },
}
</script>
